import * as React from 'react';
import '../css/style.css';
import { useState, forwardRef, useImperativeHandle, useRef } from "react"
import $ from "jquery";

function AddShortcut(props:any){
    let addNewShortCut = props.addNewShortCut;
    let addShortcutModal = props.addShortcutModal;
    let setAddShortcutModal = props.setAddShortcutModal;



    const [shortcutName, setShortcutName]= useState("");
    const [shortcutURL, setShortcutURL]= useState("");

    const submitShortcut = (event:any) => {
        event.preventDefault();
        if(shortcutName && shortcutURL){
            //setShortcuts(currentShortCutsData => [...currentShortCutsData, {'id': shortcuts.length,'sName':shortcutName,'sUrl':shortcutURL}]);
            addNewShortCut(shortcutName,shortcutURL);
            setAddShortcutModal('hide');
        }else{
            $('.dk_input').removeClass('error');

            if(!shortcutName){ $('#sName').addClass('error'); }
            if(!shortcutURL){ $('#sUrl').addClass('error'); }
        }     
    };

    return(
        <div className={'dkModal '+addShortcutModal}>
            <div className='dkModal_area'>
                <h2>Add Shortcut</h2>
                <form onSubmit={submitShortcut} className="add_shortCut_form">
                    <div className="dkFiled forName">
                        <label htmlFor="sName">Name</label> <input onChange={event=>{setShortcutName(event.target.value)}} type="text" id='sName' name="sName" className="dk_input" value={shortcutName}/>
                    </div>
                    <div className="dkFiled forUrl">
                        <label htmlFor="sUrl">URL</label> <input onChange={event=>{setShortcutURL(event.target.value)}} type="text" id='sUrl' name="sUrl" className="dk_input" value={shortcutURL}/>
                    </div>
                    <div className="flex dkModal_footer">
                        <button type="submit">Save</button><button type="button" onClick={()=>setAddShortcutModal('close')}>Cancel</button>
                    </div>
                </form>
            </div>    
        </div>

    )
}

export default AddShortcut;