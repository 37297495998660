import * as React from 'react';
import '../css/style.css';

function Singleshortcut(props:any){

    let single = props.getN;
    let removeShortcut = props.removeShortcut;
    let clickShortcut = props.clickShortcut;
    var faviconURL = 'https://www.google.com/s2/favicons?domain='+single.sUrl+'&sz=128';
    if(single.sName == 'Chrome Store'){
        faviconURL = 'https://icons.duckduckgo.com/ip3/chromewebstore.google.com.ico';
    }
    return(
        <div className="flex single_shortcut text-center" row-key={single.id}>
            <div className='mainDiv'>
                <div className='single_shortcut_area' onClick={()=>clickShortcut(single)}>
                    <span className="shortcut-title"><pre>{single.sName}</pre></span>
                    <span className="shortcut_img"><img src={faviconURL} alt="Favicon"/></span>
                    <span className="shortcut-down-arrow"></span> 
                </div>
                <div className="shortcut-close" onClick={()=>removeShortcut(single.id)}><i className="fas fa-times-circle"></i></div>
            </div>
        </div>
    )
}

export default Singleshortcut;