import * as React from 'react';
import { useState } from "react"
import YoutubeM from '../Models/YoutubeM';



function SearchBar(props:any){
    const [searchType, setSearchType] = React.useState("all");
    const typeClick =  (event:any) => {
        try{
            localStorage.setItem('searchType', event);
        }catch(err){}
        setSearchType(event);
    }
    const [query, setQuery]= useState("");

    const submitForm = (e:any) => {  
        e.preventDefault(); 
        var url = 'https://darkmodemaster-com.s1search.co/serp?segment=dark1';
        if(query && query.length > 0){
            url = 'https://darkmodemaster-com.s1search.co/serp?q='+encodeURIComponent(query)+'&segment=dark1';
        }
        window.open(url, '_blank');
    }

    return(
        <div className="dkSearch_section_top dk_container">
            <div className='dk_dkSearch_menu'>
                <ul className='flex dk_flex'>
                    <li className={searchType === 'all' ? 'search_option active': 'search_option'} onClick={()=>typeClick('all')}>All</li>
                    <li className={searchType === 'images' ? 'search_option active': 'search_option'} onClick={()=>typeClick('images')}>Images</li>
                    <li className={searchType === 'videos' ? 'search_option active': 'search_option'} onClick={()=>typeClick('videos')}>Videos</li>
                    <li className={searchType === 'news' ? 'search_option active': 'search_option'} onClick={()=>typeClick('news')}>News</li>
                </ul>
            </div>
            <form onSubmit={submitForm} className='dk_search dk_dkSearch_form searchMain'>
                <input id="query" name="query" onChange={event=>{setQuery(event.target.value)}} className="dk_form_control" value={query} />
                <button type="submit" className=""> <i className="fas fa-search"></i> </button>
            </form>    
        </div>
    )
}

export default SearchBar;


