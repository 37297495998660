import * as React from 'react';
import { useState, forwardRef, useImperativeHandle, useRef } from "react"
import '../css/style.css';
import Singleshortcut from '../components/Singleshortcut';
import ShortcutM from '../Models/ShortcutM';

const Shortcut = forwardRef((props:any, ref) => {
    let setAddShortcutModal = props.setAddShortcutModal;

    const systemShortcuts = [
        {'id':0,'sName':'Chrome Store', 'sUrl':'https://chromewebstore.google.com/detail/dark-mode-master/lkhpdekefookichfjfenhopbjcnddedb?hl=en'},
        {'id':1,'sName':'YouTube', 'sUrl':'https://youtube.com/'},
        {'id':2,'sName':'Facebook', 'sUrl':'https://facebook.com'},
        {'id':3,'sName':'MSN', 'sUrl':'https://msn.com'},
        {'id':4,'sName':'Trivago', 'sUrl':'https://trivago.com/'},
        {'id':5,'sName':'Beauty Forever', 'sUrl':'https://beautyforever.com/'},
        {'id':6,'sName':'Noom', 'sUrl':'https://noom.com/'},
        {'id':7,'sName':'Zenni Optical', 'sUrl':'https://zennioptical.com/'},
        {'id':8,'sName':'Venus', 'sUrl':'https://venus.com/'},
        {'id':9,'sName':'Wish', 'sUrl':'https://wish.com/'},
        {'id':10,'sName':'Amazon', 'sUrl':'https://amazon.com/'},
        {'id':11,'sName':'Way Fair', 'sUrl':'https://wayfair.com/'},
        {'id':12,'sName':'TurboTax', 'sUrl':'https://turbotax.intuit.com/'},
        {'id':13,'sName':'Kayak', 'sUrl':'https://kayak.com/'},
        {'id':14,'sName':'Canva', 'sUrl':'https://Canva.com/'},
        {'id':15,'sName':'Cotton On & Co', 'sUrl':'https://cottonon.com/'},
        {'id':16,'sName':'Amazon', 'sUrl':'https://amazon.com'},

    ];
    var getSystem4 = systemShortcuts.slice(0,4);

    try{
        var checkLocal = localStorage.getItem('shortcuts');
        if(checkLocal){
            getSystem4 = JSON.parse(checkLocal);   
            if(getSystem4[0] && getSystem4[0].sName && getSystem4[0].sName != 'Chrome Store'){
                getSystem4 = [{'id':99,'sName':'Chrome Store', 'sUrl':'https://chromewebstore.google.com/detail/dark-mode-master/lkhpdekefookichfjfenhopbjcnddedb?hl=en'}].concat(getSystem4);
            }
        }
    }catch(err){}
    
    const [shortcuts, setShortcuts]= useState(getSystem4);

    
    const removeShortcut = (getIndex:any) => {
        setShortcuts(currentShortCutsData => currentShortCutsData.filter(getN => getN.id !== getIndex ));
    }; 

    const clickShortcut = (event:any) => {
        if(event.sUrl !== ''){ window.open(event.sUrl, '_blank'); }
    }  

    const addNewShortCut = (shortcutName:string,shortcutURL:string) => {
        if(shortcutURL.includes("https://")){
        }else if(shortcutURL.includes("http://")){
        }else{
            shortcutURL = "http://"+shortcutURL;
        }
        var isFound = false;
        if(shortcuts.length > 0){
            shortcuts.forEach(getN=>{
                if(getN.sName == shortcutName){
                    isFound = true;
                }
            });
        }
        if(isFound === false){
            setShortcuts(currentShortCutsData => [...currentShortCutsData, {'id': shortcuts.length,'sName':shortcutName,'sUrl':shortcutURL}]);
        }
    } 
    
    useImperativeHandle(ref, () => ({
        addNewShortCut
    }));

    React.useEffect(()=>{
        try{
            localStorage.setItem('shortcuts', JSON.stringify(shortcuts));
        }catch(err){}
    },[shortcuts]);

    return(
        <div className='dkSearch_section_btm dk_container'>
            <div className='dk_shortcut'>
                {shortcuts.map((getN) => (
                    <Singleshortcut getN ={getN} key={getN.id} removeShortcut={removeShortcut} clickShortcut={clickShortcut}/>
                ))}
                <div className="addShortcut" onClick={()=>setAddShortcutModal('show')}>+</div>
            </div>
        </div>
    )
});

export default Shortcut;