import * as React from 'react';
import { useState, forwardRef, useImperativeHandle, useRef } from "react"
import '../css/style.css';
import $ from "jquery";
import StickynoteM from '../Models/StickynoteM';
import Draggable from 'react-draggable';

const Stickynote = forwardRef((props: any, ref) => {

    var stickyshow = props.stickyshow;
    var setStickyshow = props.setStickyshow;
    let deletenotes = props.deletenotes;
    let setnoteDeletes = props.setnoteDeletes;


    const openSticky = (e: any) => {
        if (stickyshow === 'hide') { setStickyshow('show'); } else { setStickyshow('hide'); }
    }

    const makeRandomId = (length: Number) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }


    const [colorshow, setColorshow] = React.useState('hide');
    const openColorPlate = (e: any) => {
        if (colorshow === 'hide') { setColorshow('show'); } else { setColorshow('hide'); }
    }
    var colorN = '#40bb6a';
    try {
        var checkLocalColor = localStorage.getItem('noteBG');
        if (checkLocalColor) { colorN = checkLocalColor; }
    } catch (err) { }

    const [noteBG, setnoteBG] = useState(colorN);
    const changeColor = (e: any) => {
        setnoteBG(e); setColorshow('hide');
    }
    var stext = "";
    try {
        var checkLocalText = localStorage.getItem('textNote');
        if (checkLocalText) { stext = checkLocalText; }
    } catch (err) { }
    const [textNote, setTextNote] = useState(stext);
    const saveNote = (event: any) => {
        setTextNote(event)
    }
    var allsaveN: StickynoteM[] = [];
    try {
        var checkLocalNote = localStorage.getItem('notes');
        if (checkLocalNote) {
            allsaveN = JSON.parse(checkLocalNote);
        }
    } catch (err) { }
    const [notes, setNotes] = useState(allsaveN);
    //const [notes, setNotes]= useState<StickynoteM[] | []>([]);
    const copyNote = (e: any) => {
        if (textNote !== '') {
            setNotes(currentNotesData => [...currentNotesData, { 'id': makeRandomId(16), 'nText': textNote, 'nColor': noteBG, 'nX': 0, 'nY': 0 }]);
            setTextNote('')
        }
    }

    const changeColorSingle = (id: any, e: any) => {
        setNotes(currentNotesData => currentNotesData.filter(getN => {
            if (getN.id === id) {
                getN.nColor = e;
            }
            return getN;
        }));
        $('.singleShow_' + id).addClass('hide');
    }
    const saveEditnote = (id: any, e: any) => {
        setNotes(currentNotesData => currentNotesData.filter(getN => {
            if (getN.id === id) {
                getN.nText = e;
            }
            return getN;
        }));
    }

    const deleteSNote = (e: any) => {
        setNotes(currentNotesData => currentNotesData.filter(getN => getN.id !== e.id));
        setnoteDeletes(currentDeleteNotesData => [...currentDeleteNotesData, { 'id': e.id, 'nText': e.nText, 'nColor': e.nColor, 'nX': e.nX, 'nY': e.nY }]);
    }
    const sNoteAction = (e: any, name: any) => {
        if (name === 'empty_all') {
            setnoteDeletes([]);
        } else {
            if (name === 'recicle') {
                setNotes(currentNotesData => [...currentNotesData, { 'id': e.id, 'nText': e.nText, 'nColor': e.nColor, 'nX': e.nX, 'nY': e.nY }]);
                setnoteDeletes(currentDeleteNotesData => currentDeleteNotesData.filter(getN => getN.id !== e.id));
            } else if (name === 'trash') {
                setnoteDeletes(currentDeleteNotesData => currentDeleteNotesData.filter(getN => getN.id !== e.id));
            }
        }

    }

    const openColorPlateSingle = (e: any) => {
        var pEle = $('.singleShow_' + e);
        if (pEle.is(':visible')) { pEle.addClass('hide'); } else { pEle.removeClass('hide'); }
    }
    const editSNote = (e: any) => {
        $('.noteForm_' + e).removeClass('hide'); $('.noteFormA_' + e).removeClass('hide');
        $('.noteViewS_' + e).addClass('hide'); $('.noteViewA_' + e).addClass('hide');
    }
    const closeEditSNote = (e: any) => {
        

        $('.noteForm_' + e).addClass('hide'); $('.noteFormA_' + e).addClass('hide');
        $('.noteViewS_' + e).removeClass('hide'); $('.noteViewA_' + e).removeClass('hide');
    }

    const nodeRef = React.useRef<HTMLDivElement>(null);
    const [time, setTime] = useState('');
    const setReminder = (e: any) => {

    }
    useImperativeHandle(ref, () => ({
        openSticky, sNoteAction, deletenotes
    }));

    const dragEventHandler = (e, data) => {
        setNotes(currentNotesData => currentNotesData.filter(getN => {
            if (getN.id === data.node.id) {
                getN.nX = data.lastX;
                getN.nY = data.lastY;
            }
            return getN;
        }));
    }
    React.useEffect(() => {
        try {
            localStorage.setItem('notes', JSON.stringify(notes))
            localStorage.setItem('textNote', textNote);
            localStorage.setItem('noteBG', noteBG);
        } catch (err) { }
    }, [textNote, noteBG, notes]);


    return (
        <div className={"dk_sticky_sec flex " + stickyshow}>
            <div className="dk_note" style={{ backgroundColor: noteBG }}>
                <div className='dk_note_area'>
                    <div className={"colorPlate " + colorshow}>
                        <div className="arrow_left"></div>
                        <div className="flex border-2 colorList">
                            <div onClick={() => changeColor('#40bb6a')} className="default-color sColor"></div>
                            <div onClick={() => changeColor('#e160ab')} className="color-one sColor"></div>
                            <div onClick={() => changeColor('#a064af')} className="color-two sColor"></div>
                            <div onClick={() => changeColor('#5bc1ec')} className="color-three sColor"></div>
                            <div onClick={() => changeColor('#7dceb7')} className="color-four sColor"></div>
                            <div onClick={() => changeColor('#f8338c')} className="color-five sColor"></div>
                            <div onClick={() => changeColor('#f43a32')} className="color-six sColor"></div>
                            <div onClick={() => changeColor('#fab632')} className="color-seven sColor"></div>
                        </div>
                    </div>
                    <div className='note_head flex'>
                        <div className="tooltip"><div className="toolIcon" onClick={openColorPlate}><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 122.88 112.75" className="injected-svg" data-src="colors-icon.5c448819.svg" width="20px" height="20px"><g><path className="st0" d="M56.66,105.35c-5.94,4.6-13.4,7.34-21.5,7.34C15.74,112.69,0,96.95,0,77.53c0-13.47,7.58-25.17,18.7-31.07 c1.96,6.96,5.68,13.19,10.65,18.16c4.64,4.64,10.38,8.2,16.79,10.24c-0.06,0.9-0.09,1.81-0.09,2.73 C46.06,88.25,50.07,97.98,56.66,105.35L56.66,105.35z"></path><path className="st1" d="M122.88,77.59c0,19.42-15.74,35.16-35.16,35.16S52.56,97,52.56,77.59c0-0.41,0.01-0.82,0.02-1.23 c2.03,0.3,4.11,0.46,6.23,0.46c11.5,0,21.92-4.66,29.46-12.2c5.45-5.45,9.4-12.41,11.17-20.19 C113.1,49.26,122.88,62.28,122.88,77.59L122.88,77.59z"></path><path className="st2" d="M93.97,35.16c0,19.42-15.74,35.16-35.16,35.16S23.65,54.58,23.65,35.16S39.39,0,58.81,0 S93.97,15.74,93.97,35.16L93.97,35.16z"></path></g></svg></div><span className="tooltiptext">Change Color</span></div>
                        <div className='copy_note'><span onClick={copyNote}>+</span></div>
                    </div>
                    <form className="noteForm">
                        <textarea className="" onChange={event => { saveNote(event.target.value) }} value={textNote} placeholder="Create a new Sticky note...">{textNote}</textarea>
                    </form>
                </div>
            </div>
            <div className='all_saveNote'>
                {notes.map((sNote: any) => (
                    <Draggable
                        nodeRef={nodeRef}
                        key={sNote.id}
                        defaultPosition={{ x: sNote.nX, y: sNote.nY }}
                        scale={1}
                        onStop={dragEventHandler}
                    >
                        <div ref={nodeRef} className='dk_sNote' style={{ backgroundColor: sNote.nColor }} id={sNote.id}>
                            <div className='dk_sNote_area'>
                                <div className={"colorPlate hide singleShow_" + sNote.id}>
                                    <div className="arrow_left"></div>
                                    <div className="flex border-2 colorList">
                                        <div onClick={() => changeColorSingle(sNote.id, '#40bb6a')} className="default-color sColor"></div>
                                        <div onClick={() => changeColorSingle(sNote.id, '#e160ab')} className="color-one sColor"></div>
                                        <div onClick={() => changeColorSingle(sNote.id, '#a064af')} className="color-two sColor"></div>
                                        <div onClick={() => changeColorSingle(sNote.id, '#5bc1ec')} className="color-three sColor"></div>
                                        <div onClick={() => changeColorSingle(sNote.id, '#7dceb7')} className="color-four sColor"></div>
                                        <div onClick={() => changeColorSingle(sNote.id, '#f8338c')} className="color-five sColor"></div>
                                        <div onClick={() => changeColorSingle(sNote.id, '#f43a32')} className="color-six sColor"></div>
                                        <div onClick={() => changeColorSingle(sNote.id, '#fab632')} className="color-seven sColor"></div>
                                    </div>
                                </div>
                                <div className='note_head flex'>
                                    <div className="tooltip"><div className="toolIcon" onClick={() => openColorPlateSingle(sNote.id)}><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 122.88 112.75" className="injected-svg" data-src="colors-icon.5c448819.svg" width="20px" height="20px"><g><path className="st0" d="M56.66,105.35c-5.94,4.6-13.4,7.34-21.5,7.34C15.74,112.69,0,96.95,0,77.53c0-13.47,7.58-25.17,18.7-31.07 c1.96,6.96,5.68,13.19,10.65,18.16c4.64,4.64,10.38,8.2,16.79,10.24c-0.06,0.9-0.09,1.81-0.09,2.73 C46.06,88.25,50.07,97.98,56.66,105.35L56.66,105.35z"></path><path className="st1" d="M122.88,77.59c0,19.42-15.74,35.16-35.16,35.16S52.56,97,52.56,77.59c0-0.41,0.01-0.82,0.02-1.23 c2.03,0.3,4.11,0.46,6.23,0.46c11.5,0,21.92-4.66,29.46-12.2c5.45-5.45,9.4-12.41,11.17-20.19 C113.1,49.26,122.88,62.28,122.88,77.59L122.88,77.59z"></path><path className="st2" d="M93.97,35.16c0,19.42-15.74,35.16-35.16,35.16S23.65,54.58,23.65,35.16S39.39,0,58.81,0 S93.97,15.74,93.97,35.16L93.97,35.16z"></path></g></svg></div><span className="tooltiptext">Change Color</span></div>
                                    <div className='sNote_action flex dk_clear'>
                                        <div className={"flex noteViewA_" + sNote.id}>
                                            <div className='sAction edit_note'>
                                                <div className="tooltip"> <div className="toolIcon" onClick={() => editSNote(sNote.id)}><i className="fas fa-pencil-alt"></i></div><span className="tooltiptext">Edit note</span> </div>
                                            </div>
                                            <div className='sAction trash_note' onClick={() => deleteSNote(sNote)}>
                                                <div className="tooltip"> 
                                                    <div className="toolIcon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="25px" height="25px" x="0" y="0" viewBox="0 0 64 64" className="setting_svg" data-src="litter.6e9a3133.svg"><g><path d="m15 24h.074l2.574 33.46a6.028 6.028 0 0 0 5.982 5.54h16.74a6.028 6.028 0 0 0 5.982-5.54l2.574-33.46h.074a3 3 0 0 0 0-6h-3.793a18.384 18.384 0 0 0 4.5-3.293 1 1 0 0 0 0-1.414l-6-6a1 1 0 0 0 -1.575.211 25.553 25.553 0 0 1 -10.79 10.207l-.578.289h-15.764a3 3 0 0 0 0 6zm25.37 37h-16.74a4.018 4.018 0 0 1 -3.988-3.693l-.023-.307h24.762l-.023.307a4.018 4.018 0 0 1 -3.988 3.693zm4.165-6h-25.07l-2.385-31h29.84zm-1.335-45.389 4.368 4.368a16.4 16.4 0 0 1 -3.541 2.392l-3.263 1.629h-5.924a27.487 27.487 0 0 0 8.36-8.389zm-28.2 10.389h34a1 1 0 0 1 0 2h-34a1 1 0 0 1 0-2z" fill="#ffffff" data-original="#ffffff"></path><path d="m24 53a3 3 0 0 0 3-3v-19a3 3 0 0 0 -6 0v19a3 3 0 0 0 3 3zm-1-22a1 1 0 0 1 2 0v19a1 1 0 0 1 -2 0z" fill="#ffffff" data-original="#ffffff"></path><path d="m32 53a3 3 0 0 0 3-3v-19a3 3 0 0 0 -6 0v19a3 3 0 0 0 3 3zm-1-22a1 1 0 0 1 2 0v19a1 1 0 0 1 -2 0z" fill="#ffffff" data-original="#ffffff"></path><path d="m40 53a3 3 0 0 0 3-3v-19a3 3 0 0 0 -6 0v19a3 3 0 0 0 3 3zm-1-22a1 1 0 0 1 2 0v19a1 1 0 0 1 -2 0z" fill="#ffffff" data-original="#ffffff"></path><path d="m19.293 13.707a1 1 0 0 0 .707.293h10a1 1 0 0 0 .707-1.707l-.757-.757a13.4 13.4 0 0 1 -3.95-9.536 1 1 0 0 0 -1-1h-10a1 1 0 0 0 -1 1 15.385 15.385 0 0 0 4.536 10.95zm4.739-10.707a15.346 15.346 0 0 0 3.628 9h-7.246l-.464-.464a13.386 13.386 0 0 1 -3.914-8.536z" fill="#ffffff" data-original="#ffffff"></path><path d="m34 6h2v2h-2z" fill="#ffffff" data-original="#ffffff"></path><path d="m13 14h2v2h-2z" fill="#ffffff" data-original="#ffffff"></path><path d="m42 1h2v2h-2z" fill="#ffffff" data-original="#ffffff"></path></g></svg>
                                                    </div>
                                                    <span className="tooltiptext">Move to the trash bin</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'hide noteFormA_' + sNote.id}>
                                            <div className='sAction closeEdit' onClick={() => closeEditSNote(sNote.id)}>
                                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 457.57" className="injected-svg" data-src="tick-icon.6ec98308.svg" width="20px" height="20px"><defs></defs><path className="cls-1" d="M0,220.57c100.43-1.33,121-5.2,191.79,81.5,54.29-90,114.62-167.9,179.92-235.86C436-.72,436.5-.89,512,.24,383.54,143,278.71,295.74,194.87,457.57,150,361.45,87.33,280.53,0,220.57Z"></path></svg>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='s_body'>
                                    <p className={"noteViewS_" + sNote.id}>{sNote.nText}</p>
                                    <form className={"hide noteForm noteForm_" + sNote.id}>
                                        <textarea className="" onChange={event => { saveEditnote(sNote.id, event.target.value) }} value={sNote.nText} placeholder="Create a new Sticky note..." autoFocus>{sNote.nText}</textarea>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                ))}
            </div>
        </div>
    )
});

export default Stickynote;


