import * as React from 'react';
import '../css/style.css';
import { useState, forwardRef, useImperativeHandle, useRef } from "react"
import $ from "jquery";

const Weather = forwardRef((props:any, ref) => {
    let weatherShow = props.weatherShow;
    let setWeatherShow = props.setWeatherShow;

    var weatherUnitInit = 'C';
    try{
        var getWeatherUnitInit = localStorage.getItem('weatherUnit');
        if(getWeatherUnitInit){
            weatherUnitInit = getWeatherUnitInit;
        }
    }catch(err){}
    const [weatherUnit, setWeatherUnit]=  React.useState(weatherUnitInit);

    const onWeatherSuccess = (position:any) => {
        const {latitude, longitude} = position.coords; var appId = '2d2772c7c7d725c6ca641e76b2c3f410';
        var api = `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=`+appId+`&units=metric`;
        fetchData(api);
    }
    const onWeatherError = (error:any) =>{
       console.log(error.message);
    }
    const backTolocation = (e:any)=>{
        setWeatherWay('location');
        if(navigator.geolocation){
            navigator.geolocation.getCurrentPosition(onWeatherSuccess, onWeatherError);
        }else{
            alert("Your browser not support geolocation api");
        }
    }
    const submitLocation = (event:any) =>{
        event.preventDefault();
        setWeatherWay('search');
        if(city){
            var appId = '2d2772c7c7d725c6ca641e76b2c3f410';
            var api = `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=`+appId+`&units=metric`;
            fetchData(api);
        }
    }


    const fetchData =(event:any) =>{
        fetch(event).then((response) => response.json())
        .then(resData => {
            weatherDetails(resData); 
        })
        .catch( err => {
            console.log(err);
        })
    }
    const weatherDetails =(info:any) =>{
        let weatherPart = document.querySelector(".weather-part");
        if(parseInt(info.cod) === 404){ }else{
            const city = info.name;
            const country = info.sys.country;
            const {description, icon} = info.weather[0];
            const {temp, feels_like, humidity} = info.main;

            var tempInit = temp; var feels_like_init = feels_like;
            if(weatherUnit === 'F'){
                tempInit = Math.floor(((9/5)*temp) + 32);
                feels_like_init = Math.floor(((9/5)*feels_like_init) + 32);
            }
            setTemp(Math.floor(tempInit)); setFeelsLike(Math.floor(feels_like_init));
            if(icon){
                setWeatherIcon("https://openweathermap.org/img/wn/"+icon+"@2x.png");
            }
            if(weatherPart){
                $(".weather").text(description);
                $(".location .location_name").text(`${city}, ${country}`);
                $(".humidity span").text(`${humidity}%`);
            }
        }
    }
    
    var weatherCity = '';  
    try{
        var checkLocalCity = localStorage.getItem('city');
        if(checkLocalCity){
            weatherCity = checkLocalCity;
        }
    }catch(err){}
    const [city, setCity] = useState(weatherCity);



    const [temp, setTemp]=  React.useState(0);
    const [feelsLike, setFeelsLike]=  React.useState(0);
    
    var weatherW = 'location';
    try{
        var checkLocalweatherWay = localStorage.getItem('weatherWay');
        if(checkLocalweatherWay){ weatherW = checkLocalweatherWay; }
    }catch(err){}
    const [weatherWay, setWeatherWay] = useState(weatherW);

    const [weatherIcon, setWeatherIcon] = useState("");
    const openWeather = (event:any) => {
        if(weatherShow === 'hide'){ 
            setWeatherShow('show'); 
            if(city !== ''){
                var appId = '2d2772c7c7d725c6ca641e76b2c3f410';
                var api = `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=`+appId+`&units=metric`;
                fetchData(api);
            }else{
                if(navigator.geolocation){
                    navigator.geolocation.getCurrentPosition(onWeatherSuccess, onWeatherError);
                }else{
                    alert("Your browser not support geolocation api");
                }
            }   
        }else{ 
            setWeatherShow('hide');
        }
    }
    

    const weatherUnitChange = (e:any)=>{
        if(weatherUnit === 'C'){
            setWeatherUnit('F');
            setTemp(Math.floor(((9/5)*temp) + 32));
            setFeelsLike(Math.floor(((9/5)*feelsLike) + 32));
        }else{
            setWeatherUnit('C');

            setTemp(Math.floor(((5/9)*(temp-32))));
            setFeelsLike(Math.floor(((5/9)*(feelsLike-32))));
        }
    }

    useImperativeHandle(ref, () => ({
        openWeather
    }));

    React.useEffect(()=>{
        try{
            localStorage.setItem('weatherUnit', weatherUnit); 
            localStorage.setItem('weatherWay', weatherWay); 
            localStorage.setItem('city', city);
        }catch(err){}
    },[weatherUnit,weatherWay, city]);

    React.useEffect(()=>{
        if( weatherShow === 'show' && city){
            var appId = '2d2772c7c7d725c6ca641e76b2c3f410';
            var api = `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=`+appId+`&units=metric`;
            fetchData(api);
        }
    },[]);

    return(
        <div className={'dk_weather '+weatherShow}>
            <div className='dk_weather_area'>
                <div className='weather_head'>
                    <div className="items-center flex">
                        <div className={weatherWay === 'location' ? 'tooltip location_BTN active': 'tooltip location_BTN'} onClick={backTolocation}> 
                            <div className="toolIcon"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 122.88 122.88" className="injected-svg" data-src="current-location-icon.d7950b41.svg" width="20px" height="20px"><g><path d="M68.23,13.49c10.44,1.49,19.79,6.36,26.91,13.48c7.29,7.29,12.23,16.93,13.58,27.68h14.17v13.58h-14.39 c-1.62,10.13-6.42,19.2-13.36,26.13c-7.11,7.11-16.47,11.99-26.91,13.48v15.04H54.65v-15.04c-10.44-1.49-19.79-6.36-26.9-13.48 c-6.94-6.94-11.74-16-13.36-26.13H0V54.65h14.16c1.35-10.75,6.29-20.39,13.58-27.68c7.11-7.11,16.46-11.99,26.9-13.48V0h13.58 V13.49L68.23,13.49z M61.44,35.41c13.95,0,25.25,11.31,25.25,25.25c0,13.95-11.31,25.25-25.25,25.25 c-13.95,0-25.25-11.31-25.25-25.25C36.19,46.72,47.49,35.41,61.44,35.41L61.44,35.41z M89,33.11c-7.05-7.05-16.8-11.42-27.56-11.42 c-10.76,0-20.51,4.36-27.56,11.42c-7.05,7.05-11.42,16.8-11.42,27.56c0,10.76,4.36,20.51,11.42,27.56 c7.05,7.05,16.8,11.42,27.56,11.42c10.76,0,20.51-4.36,27.56-11.42c7.05-7.05,11.42-16.8,11.42-27.56 C100.41,49.9,96.05,40.16,89,33.11L89,33.11z"></path></g></svg></div><span className="tooltiptext">Your local temperature</span> 
                        </div>
                        <form onSubmit={submitLocation} className="relative weather_form"> 
                            <input className="" onChange={event=>{setCity(event.target.value)}} id="city" name="city" type="text" placeholder="Type to search"/>
                            <button type="submit" className=""> <i className="fas fa-search"></i> </button>
                        </form>  
                    </div>
                </div>
                <div className="weather-part">
                    <div className="location flex"> <i className='bx bx-map'></i> <span className='location_name'>_, _</span>
                        <div className="dk_CF cursor-pointer">
                            <span>|</span>
                            <span className="dk_CF_BTN" onClick={weatherUnitChange}>° {weatherUnit === 'C' ? 'F' : 'C'} </span>
                        </div>
                    </div>
                    <img src={weatherIcon} alt=""/>
                    <div className="temp mainTemp"> <span className="numb">{temp}</span> <span className="deg">°{ weatherUnit }</span><div className="weather">_ _</div> </div>    
                    <div className="bottom-details">
                        <div className="dk_column feels">
                            <i className='bx bxs-thermometer'></i>
                            <div className="details">
                                <div className="temp"> 
                                    <span className="numb-2">{feelsLike}</span> 
                                    <span className="deg">°</span>
                                    { weatherUnit }
                                </div>
                                <p>Feels like</p>
                            </div>
                        </div>
                        <div className="dk_column humidity"> <i className='bx bxs-droplet-half'></i> <div className="details"> <span>_</span> <p>Humidity</p> </div> </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
});

export default Weather;


