import * as React from 'react';
import { useState } from "react"
import YoutubeM from '../Models/YoutubeM';



function Youtube(props:any){
    let videoshow = props.videoshow; let openYoutube = props.openYoutube;

    const [yQuery, setYQuery]= useState("");
    const [videoId, setVideoId]= useState("");
    const [videos, setVideos]= useState<YoutubeM[] | []>([]);
    const [videoListShow, setVideoListShow] = useState('show')
    const closeListVideo = (e:any)=> {
        if(videoListShow === 'hide'){ setVideoListShow('show'); }else{ setVideoListShow('hide'); }
    }
    const youtubeSubmit = (e:any) => {    e.preventDefault();

        fetch("https://www.googleapis.com/youtube/v3/search?part=snippet&maxResults=20&key=AIzaSyDC-AQeKIogc-mHZzAYUh-mjmzCvVeKo7A&q="+yQuery, {
            method: "GET",
        }).then((response) => response.json())
        .then(resData => {
            var videosHere : YoutubeM[] = [];
            (resData.items).forEach((getN:any)=>{
                videosHere.push({ 'id':getN.id.videoId, 'title':getN.snippet.title, 'thumbnail':getN.snippet.thumbnails.default.url, 'des':getN.snippet.description})
            })
            setVideos(videosHere);setVideoListShow('show');
        });
    }

    return(
        <div className={"youtube-area flex "+videoshow}>
            {videos.length > 0 &&
                <div className={'youtube-area-items dk_card_EX '+videoListShow}>
                    <div className='right_arrow'></div>
                    <div className='closeBTN' onClick={closeListVideo}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg></div>
                    <div className="video_area">
                        <ul>
                            {videos.map((getN) => (
                                <li className='sVideo flex' key={getN.id} onClick={event=>{setVideoId(getN.id)} }>
                                    <div className='vImg'><img src={getN.thumbnail} alt="" /></div>
                                    <div className='vInfo'>
                                        <h5>{getN.title}</h5>
                                        <p>{getN.des}</p>
                                    </div>  
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            }
            <div className='video_right'>
                <div className="youtube-area-search dk_card_EX">
                    <form onSubmit={youtubeSubmit} action="" className='youtube_submit'>
                        <input onChange={event=>setYQuery(event.target.value)} type="text" placeholder='Type to Search' />
                        <button><i className="fas fa-search"></i></button>
                    </form>
                    <div className='close'>
                        <svg onClick={openYoutube} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                    </div>
                </div>
                {videoId.length > 0 &&
                    <div className="videoPlay_area">
                        <iframe width="324" height="220" src={'https://www.youtube.com/embed/'+videoId+'?autoplay=1'} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>
                }
            </div>
            
        </div>
    )
}

export default Youtube;


